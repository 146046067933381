<template>
  <div>
    <div class="container sm:px-10">
      <div class="block xl:grid grid-cols-2 gap-4">
        <!-- BEGIN: Register Info -->
        <div class="hidden xl:flex flex-col min-h-screen">
          <a href="" class="-intro-x flex items-center pt-5">
            <img alt="" class="w-6" :src="require(`@/assets/images/logo.svg`)" />
            <span class="text-white text-lg ml-3">
              <span class="font-medium">智莺支付联盟</span>
            </span>
          </a>
          <div class="my-auto">
            <img
              alt=""
              class="-intro-x w-1/2 -mt-16"
              :src="require(`@/assets/images/illustration.svg`)"
            />
               <div class="-intro-x text-white font-medium text-4xl leading-tight mt-10">
              智莺支付联盟助你一键接入<br />
              支付宝、微信、各大优惠活动
            </div>
            <!-- <div class="-intro-x mt-5 text-lg text-white">
              商家，门店，社区团购，直播进入数字营销时代
            </div> -->
          </div>
        </div>
        <!-- END: Register Info -->
        <!-- BEGIN: Register Form -->
        <div class="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
          <div
            class="my-auto mx-auto xl:ml-20 bg-white xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto"
          >
            <h2 class="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left">
              注册
            </h2>
            <!-- <div
              class="intro-x mt-2 text-gray-500 xl:hidden text-center"
            >
              A few more clicks to sign in to your account. Manage all your
              e-commerce accounts in one place
            </div> -->
            <div class="intro-x mt-8">
              <div class="phone-box">
                <input
                  v-model="registerData.phone"
                  type="text"
                  class="intro-x login__input input input--lg border border-gray-300 block mt-4"
                  placeholder="手机号"
                  :disabled="btnMsg === null"
                />
                <span class="getYZM" @click="sendMobile">{{
                  btnMsg == null ? countNum + "s后重新发送" : btnMsg
                }}</span>
              </div>

              <input
                v-model="registerData.nickname"
                type="text"
                class="intro-x login__input input input--lg border border-gray-300 block mt-4"
                placeholder="用户名"
              />
              <input
                v-model="registerData.psw"
                type="password"
                class="intro-x login__input input input--lg border border-gray-300 block mt-4"
                placeholder="密码"
                show-password
              />
              <input
                v-model="registerData.psw_confirm"
                type="password"
                class="intro-x login__input input input--lg border border-gray-300 block mt-4"
                placeholder="确认密码"
                show-password
              />

              <!--               
              <div class="intro-x w-full grid grid-cols-12 gap-4 h-1 mt-3">
                <div class="col-span-3 h-full rounded bg-theme-9"></div>
                <div class="col-span-3 h-full rounded bg-theme-9"></div>
                <div class="col-span-3 h-full rounded bg-theme-9"></div>
                <div
                  class="col-span-3 h-full rounded bg-gray-200"
                ></div>
              </div>
              <a
                href=""
                class="intro-x text-gray-600 block mt-2 text-xs sm:text-sm"
                >什么是安全密码?</a
              > -->
              <input
                v-model="registerData.code"
                type="text"
                class="intro-x login__input input input--lg border border-gray-300 block mt-4"
                placeholder="验证码"
              />
            </div>
            <div class="intro-x flex items-center text-gray-700 mt-4 text-xs sm:text-sm">
              <input
                id="remember-me"
                v-model="isLabel"
                type="checkbox"
                class="input border mr-2"
              />
              <label class="cursor-pointer select-none" for="remember-me"
                >我同意智莺的</label
              >
              <a class="text-theme-1 ml-1" href="">隐私政策</a>
            </div>
            <div class="intro-x mt-5 xl:mt-8 text-center xl:text-left">
              <button
                class="button button--lg w-full xl:w-32 text-white bg-theme-1 xl:mr-3 align-top"
                @click="getRegister"
              >
                注册
              </button>
              <button
                class="button button--lg w-full xl:w-32 text-gray-700 border border-gray-300 mt-3 xl:mt-0 align-top"
                @click="getSignUp"
              >
                登录
              </button>
            </div>
          </div>
        </div>
        <!-- END: Register Form -->
      </div>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";

import lodash from "lodash";

export default {
  data() {
    return {
      registerData: {
        phone: "",
        code: "",
        nickname: "",
        psw: "",
        psw_confirm: "",
      },
      btnMsg: "点击发送验证码",
      intervalBtn: {},
      // 倒计时周期
      countNum: 60,
      // 用于倒计时标记，true-正在倒计时
      countFlag: false,
      isPhoneBled: false,
      isLabel: false,
    };
  },

  watch: {
    // isLabel(val) {
    //   console.log(val);
    // },
  },

  mounted() {
    cash("body").removeClass("app").addClass("login");
  },
  methods: {
    getSignUp: function () {
      this.$router.push("/login");
    },
    // 倒计时
    countDown() {
      // 设置btn倒计时时显示的信息
      this.btnMsg = null;
      // 更改btn状态
      this.countFlag = !this.countFlag;
      // 设置倒计时
      this.intervalBtn = setInterval(() => {
        if (this.countNum <= 0) {
          // 重置btn提示信息
          this.btnMsg = "点击发送验证码";
          // 清除定时器
          clearInterval(this.intervalBtn);
          // 更改btn状态
          this.countFlag = !this.countFlag;
          // 重置倒计时状态
          this.countNum = 60;
        }
        // 倒计时
        this.countNum--;
      }, 1000);
    },
    // 获取验证码 --- > 防抖
    sendMobile: lodash.debounce(function () {
      if (this.btnMsg === null) {
        return;
      }

      if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.registerData.phone)) {
        this.$Toastify({
          node: cash("<span>请输入正确的手机号码。</span>")[0],
          duration: 3000,
          newWindow: true,
          close: true,
          stopOnFocus: true,
        }).showToast();
        //正则校验手机号是否合法
        return;
      }

      request({
        url: "/SmsMsg/registerSmsMsg",
        method: "POST",
        data: {
          phone: this.registerData.phone,
        },
      }).then((res) => {
        if (res.code === 0) {
          this.countDown();
        } else {
          // console.error("未知错误短信接口");
        }
      });
    }, 200),

    getRegister: lodash.debounce(function () {
      if (!/^(\w){8,16}$/.test(this.registerData.psw)) {
        // 密码检验
        this.$Toastify({
          node: cash(
            "<span>请输入正确的密码格式：只能输入8-16个字母、数字、下划线</span>"
          )[0],
          duration: 3000,
          newWindow: true,
          close: true,
          stopOnFocus: true,
        }).showToast();
  
        return;
      }

      if (!/^(\w){8,16}$/.test(this.registerData.psw_confirm)) {
        // 确认密码校验
        this.$Toastify({
          node: cash(
            "<span>请输入正确的确认密码格式：只能输入8-16个字母、数字、下划线</span>"
          )[0],
          duration: 3000,
          newWindow: true,
          close: true,
          stopOnFocus: true,
        }).showToast();

        return;
      }

      if (this.registerData.psw != this.registerData.psw_confirm) {
        // 密码校验2

        this.$Toastify({
          node: cash("<span>两次密码不同， 请重新输入</span>")[0],
          duration: 3000,
          newWindow: true,
          close: true,
          stopOnFocus: true,
        }).showToast();
        return;
      }
      if (this.registerData.code === "") {
        this.$Toastify({
          node: cash("<span>请输入验证码</span>")[0],
          duration: 3000,
          newWindow: true,
          close: true,
          stopOnFocus: true,
        }).showToast();

        return;
      }
      if (this.isLabel != true) {
        this.$Toastify({
          node: cash("<span>请输请同意我们的隐私政策条款协议入验证码</span>")[0],
          duration: 3000,
          newWindow: true,
          close: true,
          stopOnFocus: true,
        }).showToast();

        return;
      }

      request({
        url: "/register/register",
        method: "POST",
        data: {
          phone: this.registerData.phone,
          code: this.registerData.code,
          nickname: this.registerData.nickname,
          psw: this.registerData.psw,
          psw_confirm: this.registerData.psw_confirm,
        },
      }).then((res) => {
        if(res.code === 0) {
          this.$Toastify({
            node: cash("<span>注册成功，进入登陆页面</span>")[0],
            duration: 3000,
            newWindow: true,
            close: true,
            stopOnFocus: true,
          }).showToast();
          this.$router.push("/login");
        } else {
          this.$message.error(res.msg); // 未知错误
        }
      });


    }),
  },
};
</script>

<style lang="scss" scoped>
.phone-box {
  position: relative;
  /* overflow: hidden; */
}

.getYZM {
  position: absolute;
  right: 13px;
  top: 13px;
  z-index: 999;
  cursor: pointer;
  padding-left: 10px;
  border-left: 1px solid rgb(235, 235, 235);
}

.getYZM:active {
  color: #1890ff;
}

.text-theme-1 {
  cursor: pointer;
}
</style>
